import styled from 'styled-components';

import DiagnoseSVG from '../../assets/svg/diagnose';
import DiagnoseReportSVG from '../../assets/svg/diagnoseReport';
import SelectDietSVG from '../../assets/svg/selectDiet';

const OrderHeaderStepsDesktop = ({ step }) => {
  return (
    <Wrapper>
      <Step active={step === 1} done={step > 1}>
        <div>{step > 1 ? <DiagnoseSVG /> : <span>1</span>}</div>
        <span>건강진단</span>
      </Step>

      <StepConnector active />

      <Step active={step === 2} done={step > 2}>
        <div>{step > 2 ? <DiagnoseReportSVG /> : <span>2</span>}</div>
        <span>진단결과</span>
      </Step>

      <StepConnector />

      <Step active={step === 3}>
        <div>{step === 3 ? <SelectDietSVG /> : <span>3</span>}</div>
        <span>식단선택</span>
      </Step>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: none;

  ${props => props.theme.device.md} {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

const Step = styled.div`
  ${props => props.theme.device.md} {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 2.5rem 0 1.6rem;

    div {
      background: ${props => (props.active ? '#ff9d46' : props.done ? '#bdbdbd' : 'white')};
      border: 1px solid ${props => (props.active ? '#ff9d46' : '#bdbdbd')};
      color: ${props => (props.active ? 'white' : '#BDBDBD')};
      width: 3.2rem;
      height: 3.2rem;
      border-radius: 100%;
      text-align: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-right: 1rem;

      span {
        font-size: 1.8rem;
        font-weight: 700;
        vertical-align: middle;
        color: ${props => (props.active ? 'white' : '#bdbdbd')};
      }

      svg {
        vertical-align: middle;
      }
    }

    span {
      font-size: 1.6rem;
      color: ${props => (props.active ? '#ff9d46' : '#bdbdbd')};
      font-weight: 700;
    }
  }
`;

const StepConnector = styled.div`
  ${props => props.theme.device.md} {
    width: 7.5rem;
    height: 1px;
    background: #bdbdbd;
  }
`;

export default OrderHeaderStepsDesktop;
