import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Icon } from 'antd';
import * as moment from 'moment';
import Router from 'next/router';
import _ from 'lodash';
import axios from 'axios';
import { useQuery } from '@apollo/react-hooks';
import * as MeQuery from '../../views/myPage/queries';
import * as SubscriptionsQueries from '../../views/subscriptions/queries';

const ReviewButton = () => {
  const handleClick = () => {
    Router.push('/completed-list').then(() => {
      window.scrollTo(0, 0);
    });
  };
  const { data: meData } = useQuery(MeQuery.ME, {
    fetchPolicy: 'no-cache'
  });
  const name = _.get(meData, 'me.name');
  const nameExist = !_.isEmpty(name);
  const [track, setTrack] = useState({
    state: false,
    created: ''
  });
  // # 구독주문
  const { loading: subOrderLoading, data: completedSubOrderData } = useQuery(
    SubscriptionsQueries.GET_SUB_INVOICE_NO, {
      fetchPolicy: 'no-cache'
    }
  );
  // 주문완료된 주문들
  const getCompletedSubOrder = _.get(completedSubOrderData, 'completedSubOrder');
  // batchOrder가 있는 주문
  const batchOrderExist = _.filter(getCompletedSubOrder, function (params) {
    return !_.isEmpty(params.subscriptionOrder)
  });
  // 주문완료된 주문중 batchOrder가 2개 이상인 주문 필터링
  const completedSubOrder = _.filter(batchOrderExist, function(params) {
    return params.subscriptionOrder !== null && params.subscriptionOrder.batchorderSet.length > 1
  })
  // batchOrder가 2개 생성되었는지 확인
  const completedSubOrderExist = !_.isEmpty(completedSubOrder);
  // batchOrder의 두번째 운송장번호
  const SecondInvoiceNo = _.map(completedSubOrder, function(item) {
    return item.subscriptionOrder !== null && item.subscriptionOrder.batchorderSet[1].deliveryInvoiceNo
  })
  // batchorder second invoiceNo Exist
  const invoiceNoExist = !_.isEmpty(SecondInvoiceNo);
  // 운송장번호조회 상태
  // 리뷰작성이 필요한 주문 필터링
  const isCompletedSubOrderReviewCreated = _.filter(completedSubOrder, { isReviewCreated: false });
  const isReviewCreatedExist = !_.isEmpty(isCompletedSubOrderReviewCreated);
  // 리뷰작성 유효기간
  const afterDay = moment(track.created)
    .add(30, 'days')
    .format('YYYY-MM-DD');
  const today = moment().format('YYYY-MM-DD');
  const isAterDay = today < afterDay;
  // 후기작성버튼 활성화
  const subWriteReview = track.state && completedSubOrderExist && isReviewCreatedExist;
  // 식단 [배송완료]
  useEffect(() => {
    if (invoiceNoExist) {
      completedSubOrder.map(item => {
        const {
          subscriptionOrder: { batchorderSet }
        } = item;
        const dropFirstBatchOrderSet = _.drop(batchorderSet);
        const batchOrderInvoiceNo = _.map(dropFirstBatchOrderSet, function(params) {
          return params.deliveryInvoiceNo
        });
        return (
          batchOrderInvoiceNo.map(item => {
            return (
              axios({
                method: 'get',
                url: `https://apis.tracker.delivery/carriers/kr.epost/tracks/${item}`
              })
                .then(res => {
                  return res.data;
                })
                .then(data => setTrack({
                  state: data.state.text === '배송완료',
                  created: _.get(_.last(data.progresses), 'time')
                }))
            )
          })
        )
      })
    }
  }, [invoiceNoExist]);
  // # 도시락
  const [lunchBoxTrack, setLunchBoxTrack] = useState({
    state: false,
    created: ''
  })
  const { loading: boxOrderLoading, data: completedBoxOrderData } = useQuery(
    SubscriptionsQueries.GET_BOX_INVOICE_NO, {
      fetchPolicy: 'no-cache'
    }
  );
  const completedBoxOrder = _.get(completedBoxOrderData, 'completedBoxOrder');
  // orderDeliveries가 생성된 주문만 - 결제완료
  const filterOrderDeliveries = _.filter(completedBoxOrder, function (params) {
    return !_.isEmpty(params.orderDeliveries)
  })
  // isReviewCreated
  const isLunchBoxWriteReview = _.filter(filterOrderDeliveries, { isReviewCreated: false });
  // 운송장번호 가져오기
  const invoiceBoxNo = _.compact(
    _.map(isLunchBoxWriteReview, function(params) {
      return params.orderDeliveries[0].deliveryInvoiceNo;
  }))
  // 운송장번호 조회 여부
  const invoiceBoxNoExist = !_.isEmpty(invoiceBoxNo);
  const isLunchBoxWriteReviewExist = !_.isEmpty(isLunchBoxWriteReview);
  // 리뷰작성 유효기간
  const LunchBoxAfterDay = moment(lunchBoxTrack.created)
    .add(7, 'days')
    .format('YYYY-MM-DD');
  const LunchBoxisAterDay = today < LunchBoxAfterDay;
  const lunchBoxWriteReview =
    lunchBoxTrack.state && invoiceBoxNoExist && isLunchBoxWriteReviewExist && !LunchBoxisAterDay;
  useEffect(() => {
    if (invoiceBoxNoExist) {
      invoiceBoxNo.map(item => {
        return (
          axios({
            method: 'get',
            url: `https://apis.tracker.delivery/carriers/kr.epost/tracks/${item}`
          })
            .then(res => {
              return res.data;
            })
            .then(data => setLunchBoxTrack({
              state: data.state.text === '배송완료',
              created: _.get(_.last(data.progresses), 'time')
            }))
        )
      })
    }
  }, [invoiceBoxNoExist]);
  const handleClose = () => {
    setLunchBoxTrack({
      status: false
    });
    setTrack({
      status: false
    });
  };
  return (
    <>
      {nameExist && (subWriteReview || lunchBoxWriteReview) ? (
        <ButtonContainer>
          <Button onClick={handleClick}>
            <strong>{name || '고객'}</strong>님, 후기 작성하고 적립금받아가세요.
            <Icon
              style={{ fontSize: '2rem', color: 'white', marginLeft: '0.5rem' }}
              type="arrow-right"
            />
          </Button>
        </ButtonContainer>
      ) : null}
    </>
  );
};
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background: #2f6ecd;
  padding: 0.5rem;
  height: 4rem;
`;
const Button = styled.a`
  display: flex;
  align-items: center;
  color: white;
  font-size: 1.2rem;
  ${props => props.theme.device.md} {
    font-size: 1.6rem;
  }
`;
const CloseButton = styled.button`
  font-size: 1.2rem;
  cursor: pointer;
  color: white;
  ${props => props.theme.device.md} {
    font-size: 1.6rem;
  }
`;
export default ReviewButton;
