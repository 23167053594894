import React from 'react';

const SvgComponent = props => (
  <svg width={25} height={16} fill="none" {...props}>
    <path
      d="M1.138 7.932h4.176l2.85-6.575 3.348 13.906 3.846-11.351 3.4 4.02h4.886"
      stroke="#fff"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
