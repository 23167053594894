import styled from 'styled-components';

import CheckSVG from '../../assets/svg/check';

const OrderHeaderStepsMobile = ({ step }) => {
  return (
    <Wrapper>
      <Step active={step === 1} checked={step > 1}>
        {step > 1 ? <CheckSVG /> : 1}
      </Step>
      <StepConnector checked={step > 1} />
      <Step active={step === 2} checked={step > 1}>
        {step > 2 ? <CheckSVG /> : 2}
      </Step>
      <StepConnector checked={step > 2} />
      <Step active={step === 3}>3</Step>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;

  ${props => props.theme.device.md} {
    display: none;
  }
`;

const Step = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 100%;
  border: 1px solid ${props => (props.active || props.checked ? '#ff9d46' : '#BDBDBD')};
  color: ${props => (props.active ? 'white' : '#BDBDBD')};
  background-color: ${props => (props.active ? '#ff9d46' : 'white')};
`;

const StepConnector = styled.div`
  height: 1px;
  width: 1.2rem;
  background-color: ${props => (props.checked ? '#ff9d46' : '#bdbdbd')};
`;

export default OrderHeaderStepsMobile;
