/* eslint-disable consistent-return */
import { useState } from 'react';
import styled from 'styled-components';
import { Icon } from 'antd';

import { useQuery } from '@apollo/react-hooks';

import Router, { useRouter } from 'next/router';
import Link from 'next/link';

import Container from '../Container';
import OrderHeaderStepsMobile from '../OrderHeaderStepsMobile';
import OrderHeaderStepsDesktop from '../OrderHeaderStepsDesktop';
import ReviewButton from '../ReviewButton';
import * as AuthQueries from '../../views/auth/queries';
import LogoSVG from '../../assets/svg/logo';
import HomeSVG from '../../assets/svg/home';

import kidneyLogo from '../../assets/images/kidney_BI_Logo.png';

const OrderHeader = ({ step }) => {
  const {
    data: {
      auth: { isLoggedIn }
    }
  } = useQuery(AuthQueries.AUTH);
  const router = useRouter();
  const handleLogin = () => {
    Router.push({
      pathname: '/login',
      query: { redirectTo: router.asPath }
    }).then(() => {
      window.scrollTo(0, 0);
    });
  };
  // # 후기작성하기 버튼 활성화 상태
  const [reviewButtonActive, setReviewButtonActive] = useState(true);
  return (
    <>
      <Wrapper>
        <StyledContainer>
          <Main>
            <Home>
              <Link href="/">
                <a>
                  <HomeSVG />
                </a>
              </Link>
            </Home>
            <Title>
              {step === 1 && '건강진단'}
              {step === 2 && '진단결과'}
              {step === 3 && '끼니선택'}
            </Title>
          </Main>

          <OrderHeaderStepsMobile step={step} />

          <Logo>
            <Link href="/">
              <a>
                {/* <LogoSVG /> */}
                <img src={kidneyLogo} alt="로고" style={{ width: '12rem' }} />
              </a>
            </Link>
          </Logo>

          <OrderHeaderStepsDesktop step={step} />

          <Util>
            {isLoggedIn ? (
              <Me>
                {/* <Link href="/my-page"> */}
                <a
                  onClick={() => {
                    window.location.href = '/my-page';
                  }}
                >
                  <Icon type="user" style={{ fontSize: '2.2rem', color: '#2f6ecd' }} />
                  <span>마이페이지</span>
                </a>
                {/* </Link> */}
              </Me>
            ) : (
              <Auth onClick={handleLogin}>
                <a type="button">로그인</a>
              </Auth>
            )}
          </Util>
        </StyledContainer>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  height: 5rem;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  /* position: relative; */
  /* z-index: 90; */

  /* ${props => {
    if (props.isSticky) {
      return `
        position: fixed;
        top: 0;
        width: 100%;
      `;
    }
  }} */
  z-index: 10;
  ${props => props.theme.device.md} {
    height: 7.2rem;
  }
`;

const StyledContainer = styled(Container)`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${props => props.theme.device.md} {
    margin: 0 auto;
    max-width: 1200px;
    justify-content: space-between;
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  ${props => props.theme.device.md} {
    display: none;
  }
`;

const Home = styled.div`
  margin-right: 1rem;
`;

const Title = styled.div`
  color: #535353;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1;
`;

const Logo = styled.div`
  display: none;

  ${props => props.theme.device.md} {
    display: block;
  }

  a {
    svg {
      width: 68px;
      ${props => props.theme.device.md} {
        width: 98px;
      }
    }
  }
`;

const Util = styled.div`
  display: none;

  ${props => props.theme.device.md} {
    display: block;
  }
`;

const Me = styled.div`
  a {
    svg {
      vertical-align: middle;
      margin-right: 1.3rem;
    }

    span {
      vertical-align: middle;
      color: #2f6ecd;
      font-size: ${props => props.theme.fontSize.desktop.buttonTwo};
      font-weight: bold;
    }
  }
`;

const Auth = styled.div`
  a {
    display: block;
    white-space: nowrap;
    width: 12rem;
    height: 4.2rem;
    text-align: center;
    line-height: 4.2rem;
    border: unset;
    padding: unset;
    font-size: ${props => props.theme.fontSize.desktop.buttonTwo};
    font-weight: 700;
    color: white;
    background-color: #fd9c46;
    cursor: pointer;
  }
`;

export default OrderHeader;
