import styled from 'styled-components';

const Container = ({ children, ...props }) => {
  return <Wrapper {...props}>{children}</Wrapper>;
};

const Wrapper = styled.div`
  ${props => props.theme.device.md} {
    max-width: 80rem;
    height: auto;
    margin: 0 auto;
  }
`;

export default Container;
