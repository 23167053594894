/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const ME = gql`
  query Me {
    me {
      id
      username
      name
    }
  }
`;
