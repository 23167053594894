import gql from 'graphql-tag';

const FRAGMENT_S_ORDER = gql`
  fragment nodeSubscriptionOrder on OrderType {
    subscriptionOrder {
      created
      status
      meal {
        name
      }
      applicantName
      perDay
      perWeek
      subscription
      contact1
      address
      addressDetail
    }
  }
`;
const FRAGMENT_BANK_INFO = gql`
  fragment bankInfo on OrderType {
    payment {
      bankInfo {
        vbankName
        vbankHolder
        vbankNum
        amount
      }
    }
  }
`;
export const BANKINFO = gql`
  query AllOrder {
    allOrder(limit: 1) {
      ...bankInfo
    }
  }
  ${FRAGMENT_BANK_INFO}
`;

export const NODE_SUBSCRIPTION_ORDER = gql`
  query AllOrder {
    activeOrder: allOrder(status: "is_active", limit: 1, isSubscriptionOrder: true) {
      ...nodeSubscriptionOrder
    }
    readyPaymentOrder: allOrder(
      status: "ready_payment"
      limit: 1
      ordering: "-created"
      isSubscriptionOrder: true
    ) {
      ...nodeSubscriptionOrder
    }
  }
  ${FRAGMENT_S_ORDER}
`;
// # 식단구독/도시락에 대한 운송장번호를 조회하는 QEURY
const FRAGMENT_SUB_INVOICE_NO = gql`
  fragment subOrder on OrderType {
    subscriptionOrder {
      isReviewCreated
      batchorderSet {
        created
        deliveryInvoiceNo
      }
    }
  }
`;
const FRAGMENT_BOX_INVOICE_NO = gql`
  fragment boxOrder on OrderType {
    orderLines {
      isReviewCreated
    }
    product {
      id
    }
    orderDeliveries {
      created
      deliveryInvoiceNo
    }
  }
`;
// 식단구독의 운송장번호
export const GET_SUB_INVOICE_NO = gql`
  query AllOrder {
    completedSubOrder: allOrder(isSubscriptionOrder: true) {
      ...subOrder
    }
  }
  ${FRAGMENT_SUB_INVOICE_NO}
`;
// 도시락주문의 운송장번호
export const GET_BOX_INVOICE_NO = gql`
  query AllOrder {
    completedBoxOrder: allOrder(isSubscriptionOrder: false) {
      ...boxOrder
    }
  }
  ${FRAGMENT_BOX_INVOICE_NO}
`;
// # 후기작성여부
export const IS_REVIEW_CREATED = gql`
  query AllOrder($isReviewCreated: Boolean) {
    allOrder(isReviewCreated: $isReviewCreated) {
      isReviewCreated
      id
      created
      name
      price
      isSubscriptionOrder
      orderdeliverySet {
        id
        name
        deliveryInvoiceNo
      }
      orderLines {
        id
        name
        amount
        price
        isReviewCreated
      }
      subscriptionOrder {
        isReviewCreated
        meal {
          name
        }
        perDay
        perWeek
        subscription
        batchorderSet {
          created
          deliveryInvoiceNo
        }
      }
    }
  }
`;
export const ALL_ORDER = gql`
  query AllOrder(
    $created: DateTime
    $updated: DateTime
    $user: ID
    $applicantName: String
    $zipNo: String
    $address: String
    $addressDetail: String
    $contact1: String
    $contact2: String
    $deliveryMemo: String
    $price: Int
    $discountedPrice: Int
    $status: String
    $name: String
    $isSubscriptionOrder: Boolean
    $isReviewCreated: Boolean
    $paymentMethod: String
    $paymentInfo1: String
    $paymentInfo2: String
    $id: ID
    $limit: Int = 100
    $offset: Int
    $ordering: String
  ) {
    allOrder(
      created: $created
      updated: $updated
      user: $user
      applicantName: $applicantName
      zipNo: $zipNo
      address: $address
      addressDetail: $addressDetail
      contact1: $contact1
      contact2: $contact2
      deliveryMemo: $deliveryMemo
      price: $price
      discountedPrice: $discountedPrice
      status: $status
      name: $name
      isSubscriptionOrder: $isSubscriptionOrder
      isReviewCreated: $isReviewCreated
      paymentMethod: $paymentMethod
      paymentInfo1: $paymentInfo1
      paymentInfo2: $paymentInfo2
      id: $id
      limit: $limit
      offset: $offset
      ordering: $ordering
    ) {
      id
      isReviewCreated
      isSubscriptionOrder
      paymentMethod
      orderdeliverySet {
        id
        name
        deliveryInvoiceNo
      }
      payment {
        bankInfo {
          vbankName
          vbankHolder
          vbankNum
          amount
        }
      }
      point {
        id
        amount
        info
        created
        order {
          id
        }
      }
      paymentInfo1
      paymentInfo2
      orderLines {
        name
        price
        discountedPrice
        amount
        isReviewCreated
        productVariant {
          id
          name
          price
          discountedPrice
        }
      }
      status
      subscriptionOrder {
        isReviewCreated
        batchorderSet {
          id
          deliveryInvoiceNo
        }
        status
        meal {
          name
        }
        applicantName
        perDay
        perWeek
        extraMealDay
        subscription
        contact1
        address
        addressDetail
        activeTime
        batchorderSet {
          deliveryInvoiceNo
        }
      }
      price
      discountedPrice
      created
      updated
    }
  }
`;

export const ALL_SUBSCRIPTION_ORDER = gql`
  query AllSubscriptionOrder(
    $created: DateTime
    $updated: DateTime
    $user: ID
    $option1: ID
    $option2: ID
    $meal: ID
    $perDay: String
    $applicantName: String
    $zipNo: String
    $address: String
    $addressDetail: String
    $contact1: String
    $contact2: String
    $deliveryMemo: String
    $price: Int
    $discountedPrice: Int
    $mealPrice: Int
    $mealOptionPrice: Int
    $order: ID
    $lastSOrder: ID
    $naverOrder: ID
    $status: String
    $extraMealDay: Int
    $perWeek: String
    $subscription: String
    $receivedDate: Date
    $additionalInfo: String
    $orderingInfo: String
    $sentMealDayAmount: Int
    $activeTime: DateTime
    $id: ID
    $limit: Int
    $offset: Int
    $ordering: String
  ) {
    allSubscriptionOrder(
      created: $created
      updated: $updated
      user: $user
      option1: $option1
      option2: $option2
      meal: $meal
      perDay: $perDay
      applicantName: $applicantName
      zipNo: $zipNo
      address: $address
      addressDetail: $addressDetail
      contact1: $contact1
      contact2: $contact2
      deliveryMemo: $deliveryMemo
      price: $price
      discountedPrice: $discountedPrice
      mealPrice: $mealPrice
      mealOptionPrice: $mealOptionPrice
      order: $order
      lastSOrder: $lastSOrder
      naverOrder: $naverOrder
      status: $status
      extraMealDay: $extraMealDay
      perWeek: $perWeek
      subscription: $subscription
      receivedDate: $receivedDate
      additionalInfo: $additionalInfo
      orderingInfo: $orderingInfo
      sentMealDayAmount: $sentMealDayAmount
      activeTime: $activeTime
      id: $id
      limit: $limit
      offset: $offset
      ordering: $ordering
    ) {
      id
      order {
        name
        paymentMethod
      }
      batchTypes
      applicantName
      perDay
      perWeek
      extraMealDay
      zipNo
      address
      addressDetail
      subscription
      status
      price
      contact1
      contact2
      mealPrice
      discountedPrice
      created
      updated
      orderingInfo
    }
  }
`;

export const ALL_BATCH_ORDER = gql`
  query AllBatchOrder(
    $created: DateTime
    $updated: DateTime
    $option1: ID
    $option2: ID
    $meal: ID
    $perDay: String
    $applicantName: String
    $zipNo: String
    $address: String
    $addressDetail: String
    $contact1: String
    $contact2: String
    $deliveryMemo: String
    $price: Int
    $discountedPrice: Int
    $mealPrice: Int
    $mealOptionPrice: Int
    $order: ID
    $lastSOrder: ID
    $naverOrder: ID
    $status: String
    $extraMealDay: Int
    $perWeek: String
    $subscription: String
    $receivedDate: Date
    $additionalInfo: String
    $orderingInfo: String
    $sentMealDayAmount: Int
    $activeTime: DateTime
    $id: ID
    $limit: Int = 100
    $offset: Int
    $ordering: String
  ) {
    allBatchOrder(
      created: $created
      updated: $updated
      option1: $option1
      option2: $option2
      meal: $meal
      perDay: $perDay
      applicantName: $applicantName
      zipNo: $zipNo
      address: $address
      addressDetail: $addressDetail
      contact1: $contact1
      contact2: $contact2
      deliveryMemo: $deliveryMemo
      price: $price
      discountedPrice: $discountedPrice
      mealPrice: $mealPrice
      mealOptionPrice: $mealOptionPrice
      order: $order
      lastSOrder: $lastSOrder
      naverOrder: $naverOrder
      status: $status
      extraMealDay: $extraMealDay
      perWeek: $perWeek
      subscription: $subscription
      receivedDate: $receivedDate
      additionalInfo: $additionalInfo
      orderingInfo: $orderingInfo
      sentMealDayAmount: $sentMealDayAmount
      activeTime: $activeTime
      id: $id
      limit: $limit
      offset: $offset
      ordering: $ordering
    ) {
      id
      subscriptionOrder {
        id
        applicantName
        address
        addressDetail
        contact1
        deliveryMemo
      }
      meal {
        name
      }
      contact1
      contact2
      created
      updated
      deliveryInvoiceNo
      packDate {
        date
      }
    }
  }
`;

export const ALL_PACK_DATE = gql`
  query AllPackDate {
    allPackDate {
      results(ordering: "-date") {
        id
        created
        updated
        date
        orderFinished
        orderDueDatetime
        handleMultiProduct
        naverOrder
        cancelReason
      }
    }
  }
`;

export const SUBSCRIPTION_ORDER_PRICE = gql`
  query SubscriptionOrderPrice(
    $meal: ID
    $option1: ID
    $option2: ID
    $perWeek: SubscriptionOrderPerWeekEnumCreate!
    $perDay: SubscriptionOrderPerDayEnumCreate!
    $subscription: SubscriptionOrderSubscriptionEnumCreate!
  ) {
    subscriptionOrderPrice(
      meal: $meal
      option1: $option1
      option2: $option2
      perWeek: $perWeek
      perDay: $perDay
      subscription: $subscription
    ) {
      calculatedPrice
      discountedPrice
      calculatedDiscountPrice
    }
  }
`;
