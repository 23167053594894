import React from 'react';

const SvgComponent = props => (
  <svg width={24} height={20} fill="none" {...props}>
    <path
      d="M1 1.41h22M9 10.232h14M1 18.589h22"
      stroke="#323232"
      strokeWidth={1.4}
      strokeLinecap="square"
    />
  </svg>
)

export default SvgComponent;
