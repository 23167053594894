import React from 'react';

import styled from 'styled-components';
import Link from 'next/link';

import haccpMark from '../../assets/images/haccp_mark.png';
import eatmaplMark from '../../assets/images/eatmapl_mark.png';

import instaImg from '../../assets/images/instaImg.png';
import metaImg from '../../assets/images/metaImg.png';
import NblogImg from '../../assets/images/NblogImg.png';
import kakaoImg from '../../assets/images/kakaoImg.png';

const Footer = () => {
  return (
    <FooterBody>
      <FirstRow>
        <CustomerService>
          <CustomerServiceName>맛있저염 고객센터</CustomerServiceName>
          <a href="tel:18999708">
            <CustomerPhone>1899-9708</CustomerPhone>
          </a>
          <CustomerWorkingHours>
            상담시간 AM 10:00 ~ PM 5:00
            <br />
            점심시간 PM 12:00 ~ 13:00
            <br />
            (토,일, 공휴일 휴무)
          </CustomerWorkingHours>
          <CustomerKakaoFriend>
            <ul>
              <li>
                <a href="https://www.instagram.com/microsalts_official" target="_blank" rel="noopener noreferrer">
                  <img src={instaImg} alt="맛있저염인스타그램" />
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/microsalts" target="_blank" rel="noopener noreferrer">
                  <img src={metaImg} alt="맛있저염페이스북" />
                </a>
              </li>
              <li>
                <a href="https://blog.naver.com/eatsmypleasure" target="_blank" rel="noopener noreferrer">
                  <img src={NblogImg} alt="맛있저염네이버블로그" />
                </a>
              </li>
              <li>
                <a href="http://pf.kakao.com/_hxlCFd" target="_blank" rel="noopener noreferrer">
                  <img src={kakaoImg} alt="맛있저염카카오톡" />
                </a>
              </li>
            </ul>
          </CustomerKakaoFriend>
        </CustomerService>
        <Haccp>
          <HaccpFirstRow>
            <CompanyImage>
              <img src={eatmaplMark} alt="eatmapl마크" />
            </CompanyImage>
            <CompanyImageContent>
              <span>
                맛있저염은 의약품이 아니며 식사요법에 도움이 될 수 있는 <br />
                식단을 제공합니다 의료적 치료는 주치의와 상의해야 합니다
              </span>
            </CompanyImageContent>
          </HaccpFirstRow>
          <HaccpSecondRow>
            <HaccpImage>
              <img src={haccpMark} alt="haccp마크" />
            </HaccpImage>
            <HaccpImageContent>
              <span>
                맛있저염은 고객의 안전한 식사를 위하여 <br />
                HACCP 인증을 받았습니다
              </span>
            </HaccpImageContent>
          </HaccpSecondRow>
        </Haccp>
      </FirstRow>
      <Line />
      <SecondRow>
        <CompanyCeo>
          <span>
            (주)잇마플 | 대표:김슬기,김현지 <br />
            사업자등록번호:270-86-00786 <br />
            통신판매신고번호 : 제2022-서울강남-1817호
          </span>
        </CompanyCeo>
        <CompanyInfo>
          <CompanyAddr>
            <span>
              주소:서울특별시 강남구 테헤란로27길 29 주암빌딩 2층
              <br />
              이메일:
              <a href="mailto:microsalts@eatmapl.com">
                <YellowColor>microsalts@eatmapl.com</YellowColor>
              </a>
            </span>
            <Privacy>
              <Link href="/service">
                <PersonalInfo>이용약관 | </PersonalInfo>
              </Link>
              <Link href="/policy/privacy">
                <PersonalPolicy>개인정보처리방침</PersonalPolicy>
              </Link>
            </Privacy>
          </CompanyAddr>
        </CompanyInfo>
      </SecondRow>
    </FooterBody>
  );
};

const FooterBody = styled.div`
  background-color: #f2f2f2;
  text-align: left;
  font-family: Spoqa Han Sans;
  color: #9a9a9a;
  ${props => props.theme.device.sm} {
    padding: 2.6rem 1.9rem 6.8rem 1.6rem;
    width: 100%;
  }
`;

const CustomerService = styled.div`
  margin-right: 11.4rem;
  ${props => props.theme.device.sm} {
    width: 100%;
    margin-bottom: 1.6rem;
    margin-right: 0;
  }
`;
const Haccp = styled.div``;
const CustomerServiceName = styled.div`
  font-size: 2.1rem;
  padding-bottom: 0.2rem;
  width: 20rem;
  height: 3.1rem;
  ${props => props.theme.device.sm} {
    width: 100%;
    height: 2rem;
    font-size: 1.4rem;
    color: #444;
    line-height: 1.43;
  }
`;
const CustomerPhone = styled.div`
  font-size: 3.1rem;
  color: #ff7e1f;
  width: 17.4rem;
  height: 4.6rem;
  ${props => props.theme.device.sm} {
    width: 11.2rem;
    height: 2.9rem;
    font-size: 2rem;
    margin-top: 0.4rem;
  }
`;
const CustomerWorkingHours = styled.div`
  font-size: 2rem;
  width: 33.3rem;
  height: 9rem;
  margin-bottom: 0.6rem;
  ${props => props.theme.device.sm} {
    width: 23.3rem;
    height: 6rem;
    font-size: 1.4rem;
    color: #444;
  }
`;
const CustomerKakaoFriend = styled.span`
  width: 32.7rem;
  height: 5.8rem;
  margin-top: 1.6rem;
  ul {
    display: flex;
    list-style: none;
    padding-left: 0;
    margin-top: 2rem;
  }
  li {
    margin-right: 1rem;
  }
  img {
    width: 3.6rem;
    height: 3.6rem;
  }
  ${props => props.theme.device.sm} {
    width: 32.8rem;
    height: 2rem;
    margin-top: 1rem;
    span {
      width: 33.8rem;
    }
    ul {
      margin-top: 1.6rem;
    }
    img {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
`;
const YellowColor = styled.span`
  font-size: 2rem;
  color: #ff7e1f;
  ${props => props.theme.device.sm} {
    font-size: 1.4rem;
  }
`;
const Line = styled.div`
  margin: 0px auto;
  border: solid 1px #ddd;
  width: 104rem;
  margin-top: 3.2rem;
  ${props => props.theme.device.sm} {
    display: none;
  }
`;
const SecondRow = styled.div`
  margin-top: 3.2rem;
  padding-left: 4rem;
  display: flex;
  justify-content: center;
  ${props => props.theme.device.sm} {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    padding-left: 0px;
  }
`;
const CompanyCeo = styled.div`
  width: 40rem;
  font-size: 2rem;
  line-height: 1.8;
  margin-right: 6.4rem;
  ${props => props.theme.device.sm} {
    font-size: 1.2rem;
    color: #9a9a9a;
    margin-right: 0;
    width: 25rem;
  }
`;

const CompanyAddr = styled.div`
  width: 39.8rem;
  font-size: 2rem;
  line-height: 1.8;
  margin-bottom: 0.7rem;
  ${props => props.theme.device.sm} {
    font-size: 1.2rem;
    color: #9a9a9a;
    width: 22rem;
  }
`;

const PersonalInfo = styled.div`
  width: 9rem;
  height: 2.9rem;
  font-size: 2rem;
  cursor: pointer;
  ${props => props.theme.device.sm} {
    width: 6rem;
    height: 2rem;
    font-size: 1.4rem;
    color: #444;
  }
`;

const FirstRow = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 4rem;
  padding-left: 3.8rem;
  ${props => props.theme.device.sm} {
    display: flex;
    flex-direction: column;
    padding-top: 0px;
    padding-left: 0px;
  }
`;
const CompanyImage = styled.div`
  width: 6.55rem;
  height: 6.55rem;
  margin-right: 2.65rem;
  margin-top: 0.13rem;
  img {
    width: 6.55rem;
    height: 6.55rem;
  }
  ${props => props.theme.device.sm} {
    width: 4rem;
    height: 4rem;
    margin-right: 2rem;
    img {
      width: 4rem;
      height: 4rem;
    }
  }
`;
const HaccpFirstRow = styled.div`
  display: flex;
  margin-bottom: 2.21rem;
  ${props => props.theme.device.sm} {
    margin-bottom: 1.2rem;
    margin-top: 1rem;
  }
`;
const HaccpSecondRow = styled.div`
  display: flex;
  ${props => props.theme.device.sm} {
    margin-bottom: 2rem;
  }
`;
const CompanyImageContent = styled.div`
  width: 41.4rem;
  height: 5rem;
  font-size: 1.7rem;
  line-height: 1.47;
  margin-top: 0.7rem;
  ${props => props.theme.device.sm} {
    width: 29.2rem;
    height: 3.6rem;
    font-size: 1.2rem;
    color: #9a9a9a;
    br {
      display: none;
    }
  }
`;

const HaccpImage = styled.div`
  width: 6.55rem;
  height: 6.55rem;
  margin-right: 2.65rem;
  img {
    width: 6.55rem;
    height: 6.55rem;
  }
  ${props => props.theme.device.sm} {
    width: 4rem;
    height: 4rem;
    margin-right: 2rem;
    img {
      width: 4rem;
      height: 4rem;
    }
  }
`;
const HaccpImageContent = styled.div`
  width: 41.4rem;
  height: 5rem;
  font-size: 1.7rem;
  line-height: 1.47;
  margin-top: 0.7rem;
  ${props => props.theme.device.sm} {
    width: 19.8rem;
    height: 3.6rem;
    font-size: 1.2rem;
    color: #9a9a9a;
    br {
      display: none;
    }
  }
`;
const CompanyInfo = styled.div`
  width: 50.6rem;
  ${props => props.theme.device.sm} {
    width: 22rem;
  }
`;
const PersonalPolicy = styled.div`
  color: #9a9a9a;
  font-size: 2rem;
  cursor: pointer;
  ${props => props.theme.device.sm} {
    color: #444;
    font-size: 1.4rem;
  }
`;
const Privacy = styled.div`
  display: flex;
`;

export default Footer;

// 계층
// FooterBody;
//   FirstRow;
//     CustomerService;
//     CustomerServiceName;
//     Customerphone;
//     CustomerWorkingHours;
//     CustomerKakaoFriend;
//   Haccp;
//     HaccpFirstRow;
//       CompanyImage;
//       CompanyImageContent;
//     HaccpSecondRow;
//       HaccpImage;
//       HaccpImageContent;
//   Line;
//   SecondRow;
//     CompanyCeo;
//     CompanyAddr;
//       PersonalInfo;
