import styled from 'styled-components';
import Header from '../Header';
import OrderHeader from '../OrderHeader';
import PageMovableHeader from '../PageMovableHeader';
import Footer from '../Footer';

const Layout = ({ children, isSticky = true, withFooter = true, isOrder = false, store = false, step, hasPrevButton = false, headerTitle, beforeMoveToPrevPage }) => {
  // eslint-disable-next-line no-nested-ternary
  const header = isOrder ? <OrderHeader step={step} /> : <Header store={store} />;

  return (
    <>
      <HeaderContainer isOrder={isOrder}>{header}</HeaderContainer>
      <Content isOrder={isOrder}>{children}</Content>
      {withFooter ? <Footer /> : null}
    </>
  );
};

const HeaderContainer = styled.div`
  /* z-index: 31; */

  position: fixed;
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
  height: ${props => (props.isOrder ? '5rem' : '15.2rem')};
  width: 100%;
  z-index: 6; //one-stop 이미지 z-index 5
  background-color: #fff;
  border-bottom: ${props => (props.isOrder ? 'initail' : '1px solid #cecece')};

  ${props => props.theme.device.sm} {
    height: ${props => (props.isOrder ? '5rem' : '10.4rem')};
    width: 100%;
  }
`;
const Content = styled.div`
  background-color: #fff;
  padding-top: ${props => (props.isOrder ? '7.2rem' : '15.2rem')};

  /* padding-top: 15.2rem; */

  ${props => props.theme.device.sm} {
    padding-top: ${props => (props.isOrder ? '5rem' : '10.4rem')};
    /* padding-top: 10.4rem; */
  }
`;

export default Layout;
