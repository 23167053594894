import React from 'react';

const SvgComponent = props => (
  <svg width={18} height={22} fill="none" {...props}>
    <path
      d="M13.561 2.53h-9.64A2.92 2.92 0 0 0 1 5.45v11.963a2.92 2.92 0 0 0 2.92 2.92h9.641a2.92 2.92 0 0 0 2.92-2.92V5.451a2.92 2.92 0 0 0-2.92-2.92z"
      stroke="#fff"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.547 1v3.071M8.773 1v3.071M12.999 1v3.071"
      stroke="#fff"
      strokeWidth={1.4}
      strokeLinecap="round"
    />
    <path
      d="M5.636 12.179l2.271 2.467c.042 0 4.513-3.91 4.513-3.91"
      stroke="#fff"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
