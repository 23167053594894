import React from 'react';

const SvgComponent = props => (
  <svg width={22} height={21} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M15.208 11.245l-1.51 1.678-1.788 1.992-4.995 5.556a1.737 1.737 0 0 1-2.846-1.893c.09-.212.223-.404.388-.565l5.662-5.09 1.789-1.61 1.773-1.593M13.682 9.719a3.671 3.671 0 0 1 .648-4.3L18.75 1M23.921 6.18l-4.419 4.42a3.666 3.666 0 0 1-4.297.65M8.835 11.495a4.826 4.826 0 0 1-5.802-.9C1.126 8.688.105 5.072 2.035 3.144c1.93-1.929 5.542-.908 7.449.998a4.826 4.826 0 0 1 .9 5.802M10.386 9.943l1.522 1.368M10.12 12.927L8.837 11.5"
      stroke="#fff"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.7 12.923l5.662 5.09a1.737 1.737 0 1 1-2.458 2.458l-4.995-5.556M20.453 2.713l-3.9 3.9M22.183 4.442l-3.9 3.9"
      stroke="#fff"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
