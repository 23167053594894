import React from 'react'

const SvgComponent = props => (
  <svg width={28} height={26} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 0l14 12.25h-4.766v13.417H4.758V12.25H0L14 0zm2.308 18.667H11.69v7h4.62v-7z"
      fill="#C4C4C4"
    />
  </svg>
)

export default SvgComponent
