import React from 'react';

const SvgComponent = props => (
  <svg width={14} height={10} fill="none" {...props}>
    <path
      d="M1.51 4.516L5.2 8.25 12 1.316"
      stroke="#FF9D46"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
